.mobile { 
  .slot { 
    height: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    .slot__inner { 
      display: flex; 
    } 
    .slot__list { 
      &--vertical-providers { 
        transform: translateY(var(--startPosition__providers)); 
        &--start { 
          animation: verticalSlideProviders var(--duration__providers) cubic-bezier(0.57, 1.05, 0.81, 1.05) 1 forwards; 
        } 
      } 
      &--horizontal-providers { 
        transform: translateX(var(--startPosition__providers)); 
        &--start { 
          animation: horizontalSlideProviders var(--duration__providers) cubic-bezier(0.57, 1.05, 0.81, 1.05) 1 forwards; 
        } 
      } 
      &--vertical-prices { 
        transform: translateY(var(--startPosition__prices)); 
        &--start { 
          animation: verticalSlidePrices var(--duration__prices) cubic-bezier(0.57, 1.05, 0.81, 1.05) 1 forwards; 
        } 
      } 
      &--horizontal-prices { 
        transform: translateX(var(--startPosition__prices)); 
        &--start { 
          animation: horizontalSlidePrices var(--duration__prices) cubic-bezier(0.57, 1.05, 0.81, 1.05) 1 forwards; 
        } 
      } 
    } 
    .slot__item { 
      img { 
        width: 100%; 
        height: 100%; 
      } 
    } 
  } 
} 
 
 
@keyframes verticalSlideProviders { 
  0% { 
    transform: translateY(var(--startPosition__providers)); 
  } 
  100% { 
    transform: translateY(var(--endPosition__providers)); 
  } 
} 
 
@keyframes horizontalSlideProviders { 
  0% { 
    transform: translateX(var(--startPosition__providers)); 
  } 
  100% { 
    transform: translateX(var(--endPosition__providers)); 
  } 
} 
@keyframes verticalSlidePrices { 
  0% { 
    transform: translateY(var(--startPosition__prices)); 
  } 
  100% { 
    transform: translateY(var(--endPosition__prices)); 
  } 
} 
 
@keyframes horizontalSlidePrices { 
  0% { 
    transform: translateX(var(--startPosition__prices)); 
  } 
 
  100% { 
    transform: translateX(var(--endPosition__prices)); 
  } 
}