.mobile {
  .game-bet {
    background-color: #202342;
    border-radius: 6px;
    padding: 16px 18px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: -110px;
      left: 50%;
      transform: translateX(-50%);
      width: 461px;
      height: 200px;
      background: url('./images/game-bet-shadow.svg') no-repeat center/cover;
      fill: linear-gradient(180deg, rgba(0, 131, 255, 0.70) 0%, rgba(49, 87, 89, 0.70) 100%);
      filter: blur(18px);
      z-index: 2;
      pointer-events: none;
    }
    &__bet-input-wrapper {
      position: relative;
      z-index: 3;
    }
    &__buttons-wrapper {
      margin-top: 12px;
      position: relative;
      z-index: 3;
      display: flex;
    }
    &__button-wrapper {
      height: 57px;
      flex-shrink: 0;
      flex-basis: 50%;
      & + .game-bet__button-wrapper {
        margin-left: 10px;
      }
    }
  }
}