.mobile {
  .win {
    &__inner {
      display: flex;
      flex-direction: column;
    }
    &__ticket-thubnail-wrapper {
      position: relative;
      height: 126px;
      display: flex;
      align-items: center;
    }
    &__thubnail-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 126px;
      height: 100%;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__ticket-wrapper {
      width: 276px;
      height: 107px;
      position: relative;
      z-index: 1;
    }
    &__buttons-wrapper {
      margin-top: 28px;
      display: flex;
    }
    &__play-bonus-button-wrapper {
      flex-shrink: 0;
      width: 50%;
      height: 86px;
    }
    &__custom-button-wrapper {
      flex-shrink: 0;
      width: calc(50% - 20px);
      height: 86px;
      margin-left: 20px;
    }
  }
}