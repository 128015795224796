.mobile {
  .game {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__game-hint-wrapper {
      margin-top: 8px;
    }
    &__game-layout-wrapper {
      margin-top: 16px;
    }
    &__game-iframe-wrapper {
      margin-top: 16px;
      height: calc(100% - 140px)
    }
  }
}