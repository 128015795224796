.app.mobile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .app__game-wrapper {
    padding-top: 12px;
    background-color: #14162A;
    flex-grow: 1;
    display: flex;
    .mobile-wrapper {
      width: 100%;
    }
  }
}

.mobile-gif-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14162A;
}