.mobile {
  .finish {
    &__inner {
       width: 100%; 
       display: flex;
       flex-direction: column;
       align-items: center;
    }
    &__text {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.175px;
      text-transform: uppercase;
    }
    &__ticket-wrapper {
      width: 100%;
      height: 148px;
      margin-top: 20px;
    }
    &__buttons-wrapper {
      display: flex;
      margin-top: 28px;
      width: 100%;
    }
    &__button-wrapper {
      flex-shrink: 0;
      width: 50%;
      height: 86px;
      button {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.175px;
      }
    }
    &__custom-button-wrapper {
      flex-shrink: 0;
      width: calc(50% - 30px);
      height: 86px;
      margin-left: 30px;
    }
  }
}