.mobile {
  .button {
    height: 100%;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.03px;
    text-transform: uppercase;
    border-radius: 8px;
    position: relative;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &--shadow {
      border-radius: 8px 8px 0 0;
      &::before {
        content: '';
        position: absolute;
        border-radius: 0 0 8px 8px;
        top: 100%;
        left: 0;
        width: 100%;
        height: 5px;
        pointer-events: none;
        z-index: 1;
      }
    }
    &--primary {
      background: linear-gradient(224deg, #DDE165 0%, #ABD112 100%);
      box-shadow: 0px 4px 4px 0px rgba(99, 75, 246, 0.10);
      color: #2D2E14;
      &.button--shadow {
        &::before {
          background: linear-gradient(224deg, #C4C964 0%, #90B00D 100%);
        }
      }
    }
    &--secondary {
      background: #86D159;
      box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.14);
      color: #30691E;
      &.button--shadow {
        &::before {
          background: #57BD37;
        }
      }
    }
    &--sm {
      font-size: 12px;
    }
  }
}