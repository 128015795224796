.mobile {
  .game-header {
    height: 53px;
    background-color: #202342;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0 4px 0 13px;
    position: relative;
    &__progress-bar-wrapper {
      width: 48px;
      height: 48px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &__menu-burger {
      font-size: 24px;
      color: #ffffff;
    }
    &__logout-button-wrapper {
      margin-left: 14px;
      button {
        padding: 6px 12px;
      }
    }
    &__finish-button-wrapper {
      margin-left: 14px;
      button {
        padding: 6px 12px;
      }
    }
    &__balance-widget-wrapper {
      margin-left: auto;
      width: 78px;
      height: 45px;
      padding: 7px 8px;
      border-radius: 4px;
      border: 1px solid var(--background-active-bg, #313341);
      background: #13141A;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      span {
        color: var(--text-gray, #BBBBBD);
        font-size: 10px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
      }
    }
    &__balance-wrapper {
      margin-top: 4px;
    }
  }
}