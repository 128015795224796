.mobile {
  .price-slots {
    &__active-slot {
     .bonus-buy {
      border: 2px solid #D2DE52;
      &__price {
        color: #B4D421;
        text-shadow: 0px 0px 3px #B0D31B;
      }
     }
    }
  }
}