.mobile {
  .game-slots {
    background-color: #202342;
    border-radius: 6px;
    position: relative;
    padding: 12px 0 16px;
    min-height: 255px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 228px;
      height: 161px;
      z-index: 1;
      background: url('./images/shadow.svg') no-repeat center/cover;
      fill: linear-gradient(180deg, #B8DBFC 0%, #F8FBFE 100%);
      filter: blur(12px);
      pointer-events: none;
    }
    &__arrow {
      width: 44px;
      aspect-ratio: 1/1;
      background: url('./images/arrow-down.svg') no-repeat center/cover;
      position: relative;
      z-index: 3;
    }
    &__slot-wrapper {
      height: 244px;
      margin-top: 24px;
      position: relative;
      z-index: 3;
    }
    .slot__item {
      border: 3px solid transparent;
      &.game-slots__active-slot {
        border: 3px solid #D2DE52;
        border-radius: 6px;
        transform: scale(1.2);
        transition: 0.1s linear;
      }
    }
  }
}