html {
  font-family: 'Inter', sans-serif;
}

body {
  &.modal-open {
    overflow: hidden;
  }
}

.mobile-wrapper {
  padding: 0 12px;
}