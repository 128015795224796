.mobile {
  .bonus-buy {
    height: 80px;
    flex-shrink: 0;
    background-color: #202342;
    border: 2px solid #282C54;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__price {
      color: var(--text, #F0F0E6);
      font-size: 30px;
      font-weight: 900;
      letter-spacing: 0.375px;
    }
    &__text {
      margin-top: 7px;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.15px;
      text-transform: uppercase;
    }
  }
}