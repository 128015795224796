.mobile {
  .bet-input {
    border-radius: 4px;
    background: #394078;
    padding: 0 12px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__decrease, &__increase {
      width: 42px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2a2e5b;
      border-radius: 2px;
      span {
        color: #ffffff;
        font-size: 20px;
      }
    }
    &__value {
      color: #FFF;
      font-size: 20px;
      font-weight: 700;
    }
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}