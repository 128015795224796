.modal-template {
  position: fixed;
  inset: 0;
  z-index: 100;
  &__overlay {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.6);
  }
  &__inner {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__main {
    width: 94%;
    height: 436px;
    border-radius: 10px;
    border: 1px solid #1B1E3B;
    background: #131529;
    display: flex;
    flex-direction: column;
  }
  &__header {
    position: relative;
    border-bottom: 1px solid #1B1E3B;
  }
  &__header-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }
  &__title {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
  }
  &__description {
    margin-top: 8px;
    color: #D3D3D3;
    font-size: 12px;
    font-weight: 500;
    span {
      color: #b4d421;
    }
  }
  &__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
  }
  &__footer {
    margin-top: auto;
    border-top: 1px solid #1B1E3B;
  }
  &__footer-inner {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__footer-text {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.048px;
  }
  &__footer-link {
    margin-left: 4px;
    color: #DBE162;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.048px;
    text-decoration: underline;
    text-transform: capitalize;
  }
}