.mobile {
  .login {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    &__form {
      display: contents;
    }
    &__form-elements-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__input-wrapper {
      & + .login__input-wrapper {
        margin-top: 22px;
      }
    }
    &__button-wrapper {
      margin-top: 20px;
      height: 40px;
      width: 180px;
    }
  }
}