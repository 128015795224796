*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}