.mobile {
  .play-bonus-button {
    &__texts-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__texts-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
    }
    &__image {
      margin-left: 8px;
      width: 36px;
      height: 36px;
      background: url('./images/play.svg') no-repeat center/cover;
    }
  }
}