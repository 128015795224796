.mobile {
  .hint {
    &__list {
      display: flex;
      justify-content: space-between;
    }
    &__item {
      background: url('./images/hint-background.svg') no-repeat center/cover;
      height: 34px;
      width: calc(100% / 12 * 4);
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 12px;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 7px;
        width: 16px;
        height: 29px;
        transform: translateY(-50%);
        background: url('./images/arrow-right.svg') no-repeat center/cover;
      }
    }
    &__item-order {
      color: #B0D31B;
      text-shadow: 0px 0px 3px #B0D31B;
      font-size: 10px;
      font-weight: 700;
    }
    &__item-text {
      margin-left: 12px;
      color: #FFF;
      font-size: 10px;
      font-weight: 700;
      width: 50%;
    }
  }
}