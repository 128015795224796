.mobile {
  .ticket {
    background: url('./images/ticket.png') no-repeat center/contain;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    &--end {
      justify-content: flex-end;
    }
    &__inner {      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &__title {
      text-transform: uppercase;
      color: #D9FF28;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.175px;
    }
    &__amount {
      margin-top: 3px;
      color: #B4D421;
      text-shadow: 0px 0px 2px #B0D31B;
      font-size: 28px;
      font-weight: 800;
      letter-spacing: 0.35px;
    }
    &__description {
      margin-top: 8px;
      text-transform: uppercase;
      color: #FFF;
      font-size: 17px;
      font-weight: 700;
    }
  }
}