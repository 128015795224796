.mobile {
  .custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    &--two-line {
      flex-direction: column;
      .custom-button__texts-wrapper {
        flex-direction: row;
        span {
          & + span {
            margin-left: 4px;
          }
        }
      }
    }
    &__texts-wrapper {
      display: flex;
      align-content: center;
      flex-direction: column;
      span {
        color: #30691E;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.175px;
        text-transform: uppercase;
        & + span {
          margin-left: 0;
        }
      }
    }
    &__two-line {
      margin-top: 4px;
      color: #FFF;
      text-shadow: 0px 2px 2px #30691E;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.3px;
    }
  }
}