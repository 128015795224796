.mobile {
  .input {
    width: 100%;
    &_label-input-wrapper {
      display: flex;
      flex-direction: column;
    }
    label {
      color: #ffffff;
      font-size: 20px;
      text-transform: capitalize;
    }
    input {
      margin-top: 8px;
      border-radius: 4px;
      background: #394078;
      padding: 0 12px;
      height: 44px;
      width: 100%;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      border: none;
      outline: none;
      &::placeholder {
        color: rgba(255, 255, 255, 0.6)
      }
      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s;
      }
    }
  }
}